import React from "react"
import { Link } from "gatsby"
import Logo from "./logo"
import SearchBar from "./ searchBar.js"
import { FaRss, FaTwitter, FaGithub } from "react-icons/fa"
const LogoBrand = () => (
  <div id="page-header">
    <Link
      style={{
        boxShadow: `none`,
        textDecoration: `none`,
        color: `inherit`,
      }}
      to="/"
    >
      <Logo />
    </Link>
  </div>
)

export default ({ url, location, editable }) => {
  return (
    <div>
      <div className="flex-row-start navigation-links">
        <LogoBrand />
        <SearchBar />
        <Link className={location.pathname === "/" ? "active-page" : ""} to="/">
          Blog
        </Link>
        <Link
          className={
            location.pathname.includes("/archive") ? "active-page" : ""
          }
          to="/archive/"
        >
          Archive
        </Link>
        {/* <Link
          className={location.pathname.includes("/forum") ? "active-page" : ""}
          to="/forum/"
        >
          Forum
        </Link> */}
        <Link
          className={
            location.pathname.includes("/courses") ? "active-page" : ""
          }
          to="/courses/"
        >
          Courses
        </Link>
        <Link
          className={
            location.pathname.includes("/showdevs") ? "active-page" : ""
          }
          to="/showdevs/"
        >
          ShowDev
        </Link>

        <Link
          className={
            location.pathname.includes("/newsletter") ? "active-page" : ""
          }
          to="/newsletter/"
        >
          Newsletter
        </Link>
        <div className="flex-row-center nav-social-links">
          <a
            className={
              location.pathname.includes("/rss.xml") ? "active-page" : ""
            }
            href="/rss.xml"
          >
            <FaRss size={16} color="#f7a200" />
          </a>
          <a
            href="https://twitter.com/HieuNgu11332621"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter size={16} color="#5EAADE" />
          </a>
          <a
            href="https://github.com/htnguy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <FaGithub size={16} color="white" />{" "}
          </a>
        </div>
      </div>
    </div>
  )
}
