import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        ref.props.onClickOutside(event) // triggering the onClickOutside fn that is passed by the calling component
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter(props) {
  const wrapperRef = useRef(null)
  useOutsideAlerter({ ...wrapperRef, props })

  return (
    <div ref={wrapperRef}>
      <div>{props.children}</div>
    </div>
  )
}

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired,
}

export default OutsideAlerter
