import Typography from "typography"

const typography = new Typography({
  baseFontSize: 14,
  baseLineHeight: 1.38,
  headerFontFamily: ["Roboto Mono", "monospace"],
  bodyFontFamily: ["Roboto Mono", "monospace"],
})
export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
