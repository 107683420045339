import React, { useState } from "react"
//import { connect } from "react-redux"
import { Link, graphql, useStaticQuery } from "gatsby"
//import { updateSearch } from "../store/searchReducer.js"
import OutsideAlerter from "./outsideAlerter.js"
export default function SearchBar({ updateSearch }) {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
              tags
              cover_image {
                base
              }
              author
            }
          }
        }
      }
    }
  `)
  const posts = data.allMarkdownRemark.edges

  const [results, setResults] = useState([])
  const [search, setSearch] = useState("")
  const onChange = e => {
    setSearch(e.target.value)
    if (!e.target.value.length) {
      return setResults([])
    }
    const keywords = e.target.value.split(" ")
    setResults(
      keywords.reduce((acc, k) => {
        return acc.filter(
          ({ node }) =>
            node.frontmatter.title.toLowerCase().includes(k.toLowerCase()) ||
            node.frontmatter.author.toLowerCase().includes(k.toLowerCase()) ||
            node.frontmatter.tags.some(t => t.includes(k.toLowerCase()))
        )
      }, posts)
    )
  }
  const closeResult = e => {
    if (results.length) {
      setResults([])
    }
  }

  return (
    <div className="search-bar">
      <OutsideAlerter
        onClickOutside={closeResult}
        className="search-bar-alerter"
      >
        <div>
          <div className="flex-row-start">
            <input
              type="text"
              value={search}
              onChange={onChange}
              placeholder="Search..."
              onClick={() => onChange({ target: { value: search } })}
            />
          </div>

          {results.length ? (
            <div className="search-results flex-column-start">
              <h4>Suggestions:</h4>
              {results.map(({ node }, i) => (
                <Link key={i} to={node.fields.slug}>
                  {node.frontmatter.title}
                </Link>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </OutsideAlerter>
    </div>
  )
}

//const getStateAsProps = state => ({ search: state.search })

//export default connect(getStateAsProps, { updateSearch })(SearchBar)
