import React, { useState, useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { rhythm } from "../utils/typography"
import NavigationBar from "./navigationBar.js"
import { FaFolder } from "react-icons/fa"
function useWindowSize() {
  const isClient = typeof window === "object"

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}
const Layout = ({ location, title, children, editable }) => {
  //const rootPath = `${__PATH_PREFIX__}/`
  const currentLocation = location.pathname
    .split("/")
    .filter(i => i.length)
    .slice(-1)[0]
  const isNotAtArchive = location.pathname !== "/archive/"
  const windowSize = useWindowSize()
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          notifications {
            message
            link
            linkLabel
          }
        }
      }
    }
  `)
  const notifications = data.site.siteMetadata.notifications
  const Notifications = () => {
    return (
      <div>
        {notifications.length ? (
          <div className="notifications-container">
            {notifications.map((notification, i) => (
              <p key={i} className="announcement-notification">
                {notification.message + " "}

                {notification.link ? (
                  <a href={notification.link}>
                    {notification.linkLabel || "link"}
                  </a>
                ) : (
                  ""
                )}
              </p>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    )
  }
  const marginTop =
    windowSize.width < 450
      ? 200
      : windowSize.width < 700
      ? 200
      : windowSize.width < 1100
      ? 130
      : 100
  return (
    <div id="outer-container">
      {/* NAVIGATION BAR */}
      <div className="sticky">
        <NavigationBar
          url={location.href}
          location={location}
          editable={editable}
        />
      </div>

      <div style={{ marginTop }}>
        <Notifications />

        {/* MAIN CONTAINER */}

        <div className="main-container">
          {location.pathname === "/" ? (
            ""
          ) : (
            <div style={{ marginBottom: 10 }}>
              {location.state && location.state.prevLocation ? (
                <Link to={location.state.prevLocation.url}>
                  {location.state.prevLocation.label} >
                </Link>
              ) : (
                <Link to="/">home ></Link>
              )}
              <span> {currentLocation.split("-").join(" ")}</span>
            </div>
          )}
          {/* MAIN */}

          <main>{children}</main>
          {isNotAtArchive ? (
            <Link to="/archive/">
              {" "}
              <span className="emoji">
                <FaFolder color="FFD679" />
              </span>{" "}
              Archive
            </Link>
          ) : (
            ""
          )}

          {/* FOOTER */}
          <hr />
          <footer>
            <div className="footer-policies">
              <Link to="/privacy-policy/">Privacy</Link>·
              <Link to="/terms-and-conditions/">Terms</Link>·
              <Link to="/disclaimer/">Disclaimer</Link>·
              <Link to="/devsurvival-user-guide/">Help</Link>·
              <Link to="/guest-blog/">Guest Blog</Link>·
              <Link to="/about-me/">About</Link>·
              <Link to="/contact-info/">Contact</Link>·
              <Link to="/feedback/">Feedback</Link>
            </div>
            <div className="muted">
              © {new Date().getFullYear()} {` `}
              Devsurvival.com
            </div>
            <div id="subscribe" />
          </footer>
        </div>
      </div>
    </div>
  )
}

export default Layout
