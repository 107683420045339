const axios = require("axios")
const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://dev-survival-backend.herokuapp.com"

async function userSubscribe(user) {
  try {
    const { data: subscriber } = await axios.post(
      `${API_URL}/subscribers`,
      user
    )
    return subscriber
  } catch (err) {}
}

async function sendContactRequest({ name, email, message }) {
  userSubscribe({ name, email })

  const { data: info } = await axios.post(`${API_URL}/contact`, {
    name,
    email,
    message,
  })
  return info
}

async function addComment({ author, email, comment, postTitle, parentID }) {
  try {
    await userSubscribe({ name: author, email })
  } catch (err) {}
  const { data: returnedComment } = await axios.post(`${API_URL}/comments`, {
    author,
    comment,
    email,
    postTitle,
    parentID,
  })
  return returnedComment
}

async function updateComment(updatedComment) {
  const { data: comment } = await axios.put(
    `${API_URL}/comments/${updatedComment._id}`,
    updatedComment
  )
  return comment
}

async function getComments(postTitle) {
  const { data: comments } = await axios.get(`${API_URL}/comments/${postTitle}`)
  return comments
}

async function getEmojis() {
  const { data: emojis } = await axios.get(`${API_URL}/emoji`)
  return emojis
}

async function addEmojiToQueue(emoji) {
  const newEmoji = await axios.post(`${API_URL}/emoji/queue`, emoji)
  return newEmoji
}

async function submitBlog(data) {
  userSubscribe({
    name: `${data.firstName} ${data.lastName}`,
    email: data.email,
  })
  const { data: blog } = await axios.post(`${API_URL}/blog`, data)
  return blog
}

async function createEdit(info) {
  userSubscribe({ name: info.name, email: info.email })
  const { data: edit } = await axios.post(`${API_URL}/edits`, info)
  return edit
}

async function createPostStat(postTitle) {
  const { data: postStat } = await axios.post(`${API_URL}/poststats`, {
    postTitle,
  })
  return postStat
}

async function updatePostStat(postStat) {
  const { data: updatedStat } = await axios.put(
    `${API_URL}/poststats/${postStat._id}`,
    postStat
  )
  return updatedStat
}

async function getPostStat(postTitle) {
  const { data: postStat } = await axios.get(`${API_URL}/poststats/`, {
    data: { postTitle },
  })
  return postStat
}

async function submitFeedback(data) {
  try {
    await userSubscribe({ name: data.name, email: data.email })
  } catch (err) {}
  const { data: message } = await axios.post(`${API_URL}/feedback/`, data)
  return message
}

async function getShowcases() {
  const { data: showcases } = await axios.get(`${API_URL}/showcase`)
  return showcases
}

async function createShowcase(data) {
  try {
    await userSubscribe({ name: data.name, email: data.email })
  } catch (err) {}
  const { data: showcase } = await axios.post(`${API_URL}/showcase`, data)
  return showcase
}
async function updateShowcase(id, data) {
  const { data: showcase } = await axios.put(`${API_URL}/showcase/${id}`, data)
  return showcase
}

module.exports = {
  userSubscribe,
  sendContactRequest,
  addComment,
  updateComment,
  getComments,
  getEmojis,
  addEmojiToQueue,
  submitBlog,
  createEdit,
  createPostStat,
  updatePostStat,
  getPostStat,
  submitFeedback,
  getShowcases,
  createShowcase,
  updateShowcase,
}
